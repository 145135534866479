import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loom-a-per',
  templateUrl: './loom-a-per.component.html',
  styleUrls: ['./loom-a-per.component.scss']
})
export class LoomAPerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
