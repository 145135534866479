import { Component, OnInit } from '@angular/core';
import { conversationalLocation } from 'src/app/_site-wide-constants/constants';

@Component({
  selector: 'app-home-tab',
  templateUrl: './home-tab.component.html',
  styleUrls: ['./home-tab.component.scss']
})
export class HomeTabComponent implements OnInit {

  conversationalLocation = conversationalLocation;

  constructor() { }

  ngOnInit() {
  }

}
