import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookshelves-a-per',
  templateUrl: './bookshelves-a-per.component.html',
  styleUrls: ['./bookshelves-a-per.component.scss']
})
export class BookshelvesAPerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
