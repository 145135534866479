export const currentUpdateDate = "October 2021";

export const currentLocation = "King George, VA 22485"

export const conversationalLocation = "King George, Virginia";

export const currentEmail = 'johnwsurina@outlook.com'

export const currentPhoneNumber = '(571) 264-7201'

export const currentLinkedIn = 'http://www.linkedin.com/in/john-surina-9373b4119'