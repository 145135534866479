import { Component, OnInit } from '@angular/core';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-personal-projects',
  templateUrl: './personal-projects.component.html',
  styleUrls: ['./personal-projects.component.scss']
})
export class PersonalProjectsComponent implements OnInit {

  faFolderOpen = faFolderOpen;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  /**
   * navigates the application to the scroll, professional project set
   */
  navigateToScroll(): void {
    this.router.navigateByUrl('projects/personal/scroll')
  }

  navigateToProject(project: string){
    this.router.navigateByUrl(`projects/personal/${project}`);
  }

}
