import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drum-filler-a-pro',
  templateUrl: './drum-filler-a-pro.component.html',
  styleUrls: ['./drum-filler-a-pro.component.css']
})
export class DrumFillerAProComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
