import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sharpener-box-a-per',
  templateUrl: './sharpener-box-a-per.component.html',
  styleUrls: ['./sharpener-box-a-per.component.scss']
})
export class SharpenerBoxAPerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
