import { Component, OnInit } from '@angular/core';
import {currentUpdateDate} from '../../_site-wide-constants/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  latest_update_date: string;

  constructor() { }

  ngOnInit() {
    this.latest_update_date = currentUpdateDate;
  }

}
