import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cryo-liquid-filler-a-pro',
  templateUrl: './cryo-liquid-filler-a-pro.component.html',
  styleUrls: ['./cryo-liquid-filler-a-pro.component.css']
})
export class CryoLiquidFillerAProComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
