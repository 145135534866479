import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-projects-tab',
  templateUrl: './projects-tab.component.html',
  styleUrls: ['./projects-tab.component.css']
})
export class ProjectsTabComponent implements OnInit {

  activeProjectSet: number;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.activeProjectSet = 0; // no project set selected
    this.router.events.subscribe(() => {
      if (this.router.url == '/projects'){
        this.activeProjectSet = 0;
      }
      else if (this.router.url == '/projects/personal'){
        this.activeProjectSet = 1;
      }
      else if (this.router.url == '/projects/professional'){
        this.activeProjectSet = 2;
      }
    })
  }

  selectProject(selection: number): void{
    if (selection == 1){
      this.router.navigateByUrl('projects/personal');
    }
    else if (selection == 2){
      this.router.navigateByUrl('projects/professional');
    }
  }

}
