import { Component, OnInit } from '@angular/core';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { Router } from '@angular/router';

const validActiveSets = ['gerhart',
                         'umd'];

@Component({
  selector: 'app-professional-projects',
  templateUrl: './professional-projects.component.html',
  styleUrls: ['./professional-projects.component.scss']
})
export class ProfessionalProjectsComponent implements OnInit {

  faFolderOpen = faFolderOpen;

  activeSet: string; // variable to contain whatever the "active set" state is. (controls dropdown)

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.activeSet = '';
  }

  /**
   * Toggles the active open set of items in the tree view.
   * @param newActiveSet The new active set of items
   */
  toggleActiveSet(newActiveSet: string): void {
    if(newActiveSet == this.activeSet){
      this.activeSet = '';
    }
    else if ( validActiveSets.includes(newActiveSet) ){
      this.activeSet = newActiveSet;
    }
    else {
      throw new Error('invalid set passed');
    }
  }

  /**
   * navigates the application to the scroll, professional project set
   */
  navigateToScroll(): void {
    this.router.navigateByUrl('projects/professional/scroll')
  }

  /**
   * navigates the application to an individual project view
   */
  navigateToProject(project: string){
    this.router.navigateByUrl(`projects/professional/${project}`);
  }

}
