import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-coil-scale-a-pro',
  templateUrl: './coil-scale-a-pro.component.html',
  styleUrls: ['./coil-scale-a-pro.component.css']
})
export class CoilScaleAProComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
