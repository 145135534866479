import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeTabComponent } from './nav-tabs/home-tab/home-tab.component';
import { ProjectsTabComponent } from './nav-tabs/projects/projects-tab/projects-tab.component';
import { ResumeTabComponent } from './nav-tabs/resume-tab/resume-tab.component';
import { AboutTabComponent } from './nav-tabs/about-tab/about-tab.component';
import { ContactTabComponent } from './nav-tabs/contact-tab/contact-tab.component';
import { PersonalProjectsComponent } from './nav-tabs/projects/personal-projects/personal-projects.component';
import { ProfessionalProjectsComponent } from './nav-tabs/projects/professional-projects/professional-projects.component';
import { ProjectScrollComponent } from './nav-tabs/projects/project-scroll/project-scroll.component';
import { LoomAPerComponent } from './nav-tabs/projects/_Personal/loom-a-per/loom-a-per.component';
import { BookshelvesAPerComponent } from './nav-tabs/projects/_Personal/bookshelves-a-per/bookshelves-a-per.component';
import { PrinterStandAPerComponent } from './nav-tabs/projects/_Personal/printer-stand-a-per/printer-stand-a-per.component';
import { ClosetDeskAPerComponent } from './nav-tabs/projects/_Personal/closet-desk-a-per/closet-desk-a-per.component';
import { FirePitAPerComponent } from './nav-tabs/projects/_Personal/fire-pit-a-per/fire-pit-a-per.component';
import { SharpenerBoxAPerComponent } from './nav-tabs/projects/_Personal/sharpener-box-a-per/sharpener-box-a-per.component';
import { SiloWeighFeetAProComponent } from './nav-tabs/projects/_Professional/silo-weigh-feet-a-pro/silo-weigh-feet-a-pro.component';
import { TruckScaleAProComponent } from './nav-tabs/projects/_Professional/truck-scale-a-pro/truck-scale-a-pro.component';
import { DrumFillerAProComponent } from './nav-tabs/projects/_Professional/drum-filler-a-pro/drum-filler-a-pro.component';
import { CryoLiquidFillerAProComponent } from './nav-tabs/projects/_Professional/cryo-liquid-filler-a-pro/cryo-liquid-filler-a-pro.component';
import { CoilScaleAProComponent } from './nav-tabs/projects/_Professional/coil-scale-a-pro/coil-scale-a-pro.component';
import { BulkBagFillerAProComponent } from './nav-tabs/projects/_Professional/bulk-bag-filler-a-pro/bulk-bag-filler-a-pro.component';
import { BufferFillManifoldAProComponent } from './nav-tabs/projects/_Professional/buffer-fill-manifold-a-pro/buffer-fill-manifold-a-pro.component';

const routes: Routes = [
  {path: 'home', component:HomeTabComponent},
  {path: 'projects', component: ProjectsTabComponent, children: [
    {path: 'personal', component: PersonalProjectsComponent},
    {path: 'personal/scroll', component: ProjectScrollComponent},

    {path: 'personal/loom-a-per', component: LoomAPerComponent},
    {path: 'personal/bookshelves-a-per', component: BookshelvesAPerComponent},
    {path: 'personal/printer-stand-a-per', component: PrinterStandAPerComponent},
    {path: 'personal/closet-desk-a-per', component: ClosetDeskAPerComponent},
    {path: 'personal/fire-pit-a-per', component: FirePitAPerComponent},
    {path: 'personal/sharpener-box-a-per', component: SharpenerBoxAPerComponent},
    

    {path: 'professional', component: ProfessionalProjectsComponent},
    {path: 'professional/scroll', component: ProjectScrollComponent},

    {path: 'professional/silo-weigh-feet-a-per', component: SiloWeighFeetAProComponent},
    {path: 'professional/truck-scale-a-pro', component: TruckScaleAProComponent},
    {path: 'professional/drum-filler-a-pro', component: DrumFillerAProComponent},
    {path: 'professional/cryo-liquid-filler-a-pro', component: CryoLiquidFillerAProComponent},
    {path: 'professional/coil-scale-filler-a-pro', component: CoilScaleAProComponent},
    {path: 'professional/bulk-bag-filler-a-pro', component: BulkBagFillerAProComponent},
    {path: 'professional/buffer-fill-manifold-a-pro', component: BufferFillManifoldAProComponent}
  ]},
  {path: 'resume', component: ResumeTabComponent},
  {path: 'about', component: AboutTabComponent},
  {path: 'contact', component: ContactTabComponent},
  {path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
