import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-printer-stand-a-per',
  templateUrl: './printer-stand-a-per.component.html',
  styleUrls: ['./printer-stand-a-per.component.css']
})
export class PrinterStandAPerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
