import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './common/nav-bar/nav-bar.component';
import { HomeTabComponent } from './nav-tabs/home-tab/home-tab.component';
import { NameTagComponent } from './common/name-tag/name-tag.component';
import { FooterComponent } from './common/footer/footer.component';
import { ProjectsTabComponent } from './nav-tabs/projects/projects-tab/projects-tab.component';
import { ResumeTabComponent } from './nav-tabs/resume-tab/resume-tab.component';
import { AboutTabComponent } from './nav-tabs/about-tab/about-tab.component';
import { ContactTabComponent } from './nav-tabs/contact-tab/contact-tab.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PersonalProjectsComponent } from './nav-tabs/projects/personal-projects/personal-projects.component'

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ProfessionalProjectsComponent } from './nav-tabs/projects/professional-projects/professional-projects.component';
import { ProjectScrollComponent } from './nav-tabs/projects/project-scroll/project-scroll.component';
import { LoomAPerComponent } from './nav-tabs/projects/_Personal/loom-a-per/loom-a-per.component';
import { BookshelvesAPerComponent } from './nav-tabs/projects/_Personal/bookshelves-a-per/bookshelves-a-per.component';
import { PrinterStandAPerComponent } from './nav-tabs/projects/_Personal/printer-stand-a-per/printer-stand-a-per.component';
import { ClosetDeskAPerComponent } from './nav-tabs/projects/_Personal/closet-desk-a-per/closet-desk-a-per.component';
import { FirePitAPerComponent } from './nav-tabs/projects/_Personal/fire-pit-a-per/fire-pit-a-per.component';
import { SharpenerBoxAPerComponent } from './nav-tabs/projects/_Personal/sharpener-box-a-per/sharpener-box-a-per.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { BufferFillManifoldAProComponent } from './nav-tabs/projects/_Professional/buffer-fill-manifold-a-pro/buffer-fill-manifold-a-pro.component';
import { TruckScaleAProComponent } from './nav-tabs/projects/_Professional/truck-scale-a-pro/truck-scale-a-pro.component';
import { BulkBagFillerAProComponent } from './nav-tabs/projects/_Professional/bulk-bag-filler-a-pro/bulk-bag-filler-a-pro.component';
import { DrumFillerAProComponent } from './nav-tabs/projects/_Professional/drum-filler-a-pro/drum-filler-a-pro.component';
import { CryoLiquidFillerAProComponent } from './nav-tabs/projects/_Professional/cryo-liquid-filler-a-pro/cryo-liquid-filler-a-pro.component';
import { CoilScaleAProComponent } from './nav-tabs/projects/_Professional/coil-scale-a-pro/coil-scale-a-pro.component';
import { SiloWeighFeetAProComponent } from './nav-tabs/projects/_Professional/silo-weigh-feet-a-pro/silo-weigh-feet-a-pro.component';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeTabComponent,
    NameTagComponent,
    FooterComponent,
    ProjectsTabComponent,
    ResumeTabComponent,
    AboutTabComponent,
    ContactTabComponent,
    PersonalProjectsComponent,
    ProfessionalProjectsComponent,
    ProjectScrollComponent,
    LoomAPerComponent,
    BookshelvesAPerComponent,
    PrinterStandAPerComponent,
    ClosetDeskAPerComponent,
    FirePitAPerComponent,
    SharpenerBoxAPerComponent,
    BufferFillManifoldAProComponent,
    TruckScaleAProComponent,
    BulkBagFillerAProComponent,
    DrumFillerAProComponent,
    CryoLiquidFillerAProComponent,
    CoilScaleAProComponent,
    SiloWeighFeetAProComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CollapseModule.forRoot(),
    BrowserAnimationsModule,
    FontAwesomeModule,
    NgbCarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    LoomAPerComponent,
    BookshelvesAPerComponent,
    PrinterStandAPerComponent,
    ClosetDeskAPerComponent,
    FirePitAPerComponent,
    SharpenerBoxAPerComponent,
  ]
})
export class AppModule { }
