import { Component, OnInit } from '@angular/core';
import { currentLocation, currentEmail, currentPhoneNumber, currentLinkedIn } from 'src/app/_site-wide-constants/constants';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-contact-tab',
  templateUrl: './contact-tab.component.html',
  styleUrls: ['./contact-tab.component.css']
})
export class ContactTabComponent implements OnInit {

  currentLocation = currentLocation;
  currentEmail = currentEmail;
  currentPhoneNumber = currentPhoneNumber;
  currentLinkedIn = currentLinkedIn;

  linkedIn = faLinkedinIn;

  constructor() { }

  ngOnInit() {
  }

}
