import { Component, OnInit } from '@angular/core';
import { faGraduationCap,
         faScroll,
         faBolt,
         faDraftingCompass,
         faTools, 
         faCubes,
         faCode,
         faCogs,
         faDatabase,
         faDollarSign,
         faToolbox} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-about-tab',
  templateUrl: './about-tab.component.html',
  styleUrls: ['./about-tab.component.css']
})
export class AboutTabComponent implements OnInit {

  isCollapsed = false;
  collapseArray: boolean[];

  // Font awesome Icons
  faGraduationCap = faGraduationCap;
  faScroll = faScroll;
  faBolt = faBolt;
  faDraftingCompass = faDraftingCompass;
  faTools = faTools;
  faCubes = faCubes;
  faCode = faCode;
  faCogs = faCogs;
  faDatabase = faDatabase;
  faDollarSign = faDollarSign;
  faToolbox = faToolbox;

  constructor() { }

  ngOnInit() {
    this.resetCollapseArray();
  }

  collapseButtonClicked(button: number): void{
    if(this.collapseArray[button] == false){
      this.resetCollapseArray();
      return;
    }
    else{
      this.resetCollapseArray();
      this.collapseArray[button] = false;
      return
    }
  }

  resetCollapseArray(): void{
    this.collapseArray = [true,
                          true,
                          true,
                          true,
                          true,
                          true]
  }

}
