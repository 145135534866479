import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buffer-fill-manifold-a-pro',
  templateUrl: './buffer-fill-manifold-a-pro.component.html',
  styleUrls: ['./buffer-fill-manifold-a-pro.component.css']
})
export class BufferFillManifoldAProComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
