import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fire-pit-a-per',
  templateUrl: './fire-pit-a-per.component.html',
  styleUrls: ['./fire-pit-a-per.component.scss']
})
export class FirePitAPerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
