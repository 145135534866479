import { Component, OnInit } from '@angular/core';
import { LoomAPerComponent } from '../_Personal/loom-a-per/loom-a-per.component';
import { Router } from '@angular/router';
import { BookshelvesAPerComponent } from '../_Personal/bookshelves-a-per/bookshelves-a-per.component';
import { PrinterStandAPerComponent } from '../_Personal/printer-stand-a-per/printer-stand-a-per.component';
import { ClosetDeskAPerComponent } from '../_Personal/closet-desk-a-per/closet-desk-a-per.component';
import { FirePitAPerComponent } from '../_Personal/fire-pit-a-per/fire-pit-a-per.component';
import { SharpenerBoxAPerComponent } from '../_Personal/sharpener-box-a-per/sharpener-box-a-per.component';
import { SiloWeighFeetAProComponent } from '../_Professional/silo-weigh-feet-a-pro/silo-weigh-feet-a-pro.component';
import { CryoLiquidFillerAProComponent } from '../_Professional/cryo-liquid-filler-a-pro/cryo-liquid-filler-a-pro.component';
import { TruckScaleAProComponent } from '../_Professional/truck-scale-a-pro/truck-scale-a-pro.component';
import { CoilScaleAProComponent } from '../_Professional/coil-scale-a-pro/coil-scale-a-pro.component';
import { BulkBagFillerAProComponent } from '../_Professional/bulk-bag-filler-a-pro/bulk-bag-filler-a-pro.component';
import { DrumFillerAProComponent } from '../_Professional/drum-filler-a-pro/drum-filler-a-pro.component';
import { BufferFillManifoldAProComponent } from '../_Professional/buffer-fill-manifold-a-pro/buffer-fill-manifold-a-pro.component';

@Component({
  selector: 'app-project-scroll',
  templateUrl: './project-scroll.component.html',
  styleUrls: ['./project-scroll.component.scss']
})
export class ProjectScrollComponent implements OnInit {

  personalProjects = [
    LoomAPerComponent,
    BookshelvesAPerComponent,
    PrinterStandAPerComponent,
    ClosetDeskAPerComponent,
    FirePitAPerComponent,
    SharpenerBoxAPerComponent
  ]

  professionalComponents =[
    SiloWeighFeetAProComponent,
    CryoLiquidFillerAProComponent,
    TruckScaleAProComponent,
    CoilScaleAProComponent,
    BulkBagFillerAProComponent,
    DrumFillerAProComponent,
    BufferFillManifoldAProComponent
  ]

  display: String;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.display = this.router.url.split('/')[this.router.url.split('/').length - 2]
  }

}
