import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  currentUrl:String

  homeActive = false;
  projectsActive = false;
  resumeActive = false;
  aboutActive = false;
  contactActive = false;

  constructor(
    public routeRef: Router
  ) { }

  ngOnInit(){
    this.routeRef.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.currentUrl = this.routeRef.url;
      this.checkRoutes();
      // console.log([this.homeActive,this.projectsActive,this.resumeActive,this.aboutActive,this.contactActive]) for debug
    })
  }

  checkRoutes() :void {
    if (this.currentUrl.includes('/home'))
    {
      this.homeActive = true;
    }
    else{
      this.homeActive = false;
    }

    if ( this.currentUrl.includes('projects'))
    {
      this.projectsActive = true;
    }
    else{
      this.projectsActive = false;
    }

    if ( this.currentUrl.includes('resume'))
    {
      this.resumeActive = true;
    }
    else{
      this.resumeActive = false;
    }

    if ( this.currentUrl.includes('about'))
    {
      this.aboutActive = true;
    }
    else{
      this.aboutActive = false;
    }

    if ( this.currentUrl.includes('contact'))
    {
      this.contactActive = true;
    }
    else{
      this.contactActive = false;
    }
  }

}
