import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-silo-weigh-feet-a-pro',
  templateUrl: './silo-weigh-feet-a-pro.component.html',
  styleUrls: ['./silo-weigh-feet-a-pro.component.css']
})
export class SiloWeighFeetAProComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
